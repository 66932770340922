
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src

import AssetsServices from '@/services/assets-services';
import CreateAssets from "./create-assets.vue"
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "../../../services/filter-service";

// import HRMService from "../../../services/hrm-service";
// import hrmService from '../../../services/hrm-service';
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import Moment from "moment";

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar,
    CreateAssets,
    Loader,
    FilterComponent
  },
})
export default class Assets extends Vue {
  private data: any[] = [];
  private currentItem: any = null;
  private currentIndex: number = -1;
  private title: string = "";
  // private rows = 100;
  // private currentPage = 1
  filter = "";
  selected_dept = "";
  public option_dept: any = [];
  selected_branch = "";
  public option_branch: any = [];
  selected_bloodgroup = "";
  public option_bloodgroup: any = [];
  selected_effective_date_to: any = "";
  public payload: any = {}
  public fields = [
    {
      key: 'code',
      sortable: true
    },
    {
      key: 'title',
      sortable: true
    },
    {
      key: 'asset_class',
      sortable: false
    },
    {
      key: 'asset_type',
      sortable: false
    },
    {
      key: 'registration',
      sortable: true,
      // variant: 'danger'
    },
    {
      key: 'model',
      sortable: true,
      // variant: 'danger'
    },
    {
      key: 'division',
      sortable: true,
      // variant: 'danger'
    },
    {
      key: 'purchase_date',
      sortable: true,
      // variant: 'danger'
    },
    {
      key: 'purchase_amount',
      sortable: true,
      // variant: 'danger'
    },
    {
      key: 'created_by',

    },
    {
      key: 'comments',
      sortable: true,
      // variant: 'danger'
    },
  ];
  public currentPage = 1;
  get rows() {
    return this.items.length;
  }
  public items = []
  loading = false;

  retrieve() {
    this.loading = true
    AssetsServices.getAllAssets().then((response) => {
      this.items = response.data
      this.loading = false

    }).catch((err) => {
      console.log(err)
      this.loading = false

    })
    // HRMService.getAllassest()
    //   .then((response) => {
    //     this.items = response.data;
    //     // console.log(response.data);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }
  forUpdate = false;
  onRowClicked(data: any) {
    this.payload = data
    this.forUpdate = true
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create-asset");
  }
  bloodgroupchange() {

  }
  effectivedateto() {

  }
  refreshList() {
    this.retrieve();
    this.currentItem = null;
    this.currentIndex = -1;
  }

  setActiveItem(data: any, index: number) {
    this.currentItem = data;
    this.currentIndex = index;
  }

  removeAll() {
    // hrmService.deleteAll()
    //   .then((response) => {
    //     console.log(response.data);
    //     this.refreshList();
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }

  searchTitle() {
    // hrmService.findByTitle(this.title)
    //   .then((response) => {
    //     this.data = response.data;
    //     console.log(response.data);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }

  mounted() {
    this.retrieve();
  }
  reset() {
    this.payload = {};
    this.forUpdate = false;
  }
  public widths = [90, 105, 90, 90, 90, 90, 90, 90, 90, 90, 90];
  public columns = [
    { label: "Code", field: "code" },
    { label: "Title", field: "title" },
    { label: "Asset Class", field: "asset_class" },
    { label: "Asset Type", field: "asset_type" },
    { label: "Registration", field: "registration" },
    { label: "Model", field: "model" },
    { label: "Division", field: "division" },
    { label: "Purchase Date", field: "purchase_date" },
    { label: "Purchase Amount", field: "purchase_amount" },
    { label: "Created By", field: "created_by" },
    { label: "Comments", field: "comments" },
  ];
  filter_fields = [
    { type: "text", label: "Code", name: "code", value: "", param_name: "code", filter_type: "simple", },
    { type: "text", label: "Title", name: "title", value: "", param_name: "title", filter_type: "simple", },
    { type: "text", label: "Asset Class", name: "asset_class", value: "", param_name: "asset_class", filter_type: "simple", },
    { type: "text", label: "Asset Type", name: "asset_type", value: "", param_name: "asset_type", filter_type: "simple", },
    { type: "text", label: "Registration", name: "registration", value: "", param_name: "registration", filter_type: "simple", },
    { type: "text", label: "Model", name: "model", value: "", param_name: "model", filter_type: "simple", },
    { type: "text", label: "Division", name: "division", value: "", param_name: "division", filter_type: "simple", },
    { type: "date", label: "Purchase Date", name: "purchase_date", value: "", param_name: "purchase_date", filter_type: "simple", },
    { type: "number", label: "Purchase Amount", name: "purchase_amount", value: "", param_name: "purchase_amount", filter_type: "simple", },
    { type: "text", label: "Created By", name: "created_by", value: "", param_name: "created_by", filter_type: "simple", },
  ];
  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.get_asset(simpleFilteredObj).then((data) => {
        if (
          data.data.data !== undefined &&
          data.data.data !== null
        ) {
          this.items = data.data.data;
          this.loading = false;
        } else {
          this.retrieve();
        }
      });
    }
  }
  refreshFilters() {
    for (let field of this.filter_fields) {
      field.value = "";
    }
    this.retrieve();
  }
}
